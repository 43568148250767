<template>
    <div>
        <z-menu />
        <b-container class="py-5 mb-5 mt-2 text-left">
            <div v-show="!loading" class="">
                <div v-if="pageLoaded" class="prismic">
                    <prismic-rich-text :field="response.data.title" />
                    <prismic-rich-text
                        :field="response.data.description"
                        class="description mt-4"
                    />
                </div>
                <div v-else>
                    <h1>
                        Lo sentimos
                    </h1>
                    <div class="mt-4">
                        Por el momento no contamos con una campaña activa en tu ciudad. Muy pronto
                        podrás invitar a tus amigos y generar ingresos extra sin moverte un solo
                        kilómetro.
                    </div>
                </div>
            </div>
            <div v-show="loading">
                <z-loading></z-loading>
            </div>
        </b-container>
        <z-footer />
    </div>
</template>

<script>
import ZMenu from "@/components/ZMenu";
import ZFooter from "@/components/ZFooter";

export default {
    name: "Referrals",

    components: {
        ZFooter,
        ZMenu
    },

    data() {
        return {
            // Initialize "response"
            response: null,
            loading: true,
            campaignActive: true
        };
    },

    computed: {
        pageLoaded() {
            return this.response != null && this.campaignActive;
        }
    },

    created() {
        // Call the API query method
        this.getContent();
    },

    methods: {
        async getContent() {
            // Query the API and assign the response to "response"
            const path = this.$route.params.pathMatch;
            try {
                const response = await this.$prismic.client.query(
                    this.$prismic.Predicates.at("document.tags", [path])
                );

                if (response.results && response.results.length > 0) {
                    this.response = response.results[0];
                    this.campaignActive = response.results[0].data.active;
                }
            } catch (error) {
                this.$captureError(error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.prismic .description {
    img {
        display: inherit;
        margin: auto;
        height: 200px;
    }
}
</style>
